<!-- =========================================================================================
  File Name: ECommerceItemDetail.vue
  Description: eCommerce Item Detail page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="item-detail-page">

    <vs-alert :active.sync="error_occured" color="danger" title="Error Fetching Product Data">
      <span>{{ error_msg }}. </span>
      <span>
        <span>Check </span><router-link class="text-inherit underline" to="/shop">All Items</router-link>
      </span>
    </vs-alert>

    <vx-card :key="item_data.id" v-if="item_data">


      <template slot="no-body">

        <div class="item-content">

          <!-- Item Main Info -->
          <div class="product-details p-6">
            <div class="vx-row mt-6">
              <div class="vx-col md:w-2/5 w-full flex items-center justify-center">
                <div class="product-img-container w-4/5 mx-auto mb-10 md:mb-0 rounded">
                  <img :alt="item_data.name"
                       :src="`https://portal-cdn.rapid.education/product-pictures/${item_data.image||'default'}.jpeg`"
                       class="responsive rounded">
                </div>
              </div>

              <!-- Item Content -->
              <div class="vx-col md:w-3/5 w-full">

                <h3>{{ item_data.name }}</h3>

                <p class="my-2">
                  <span class="mr-2">by</span>
                  <span>{{ service_data.name }}</span>
                </p>
                <p class="flex items-center flex-wrap">
                  <span class="cursor-pointer leading-none mt-2">{{ prices_data.length }} pricing option{{
                      prices_data.length === 1 ? '' : 's'
                    }}</span>
                </p>

                <vs-divider/>

                <nl2br tag="p" :text="item_data.description" class-name="item-description" />

                <vs-divider/>

                <!-- Quantity -->
                <div class="vx-row">

                  <div class="vx-col w-full">
                    <div class="flex flex-wrap items-start mb-4">

                      <vs-row class="pb-3" vs-justify="space-between">
                        <vs-col vs-justify="space-between" vs-lg="6" vs-sm="12" vs-type="flex" v-if="!is_bundle">
                          <v-select @input="updateSelectedPrice" label="priceDisplay" :value="price_selected" :options="prices" class="w-full mt-5" />
                        </vs-col>

                        <vs-col vs-justify="space-between" vs-lg="5" vs-sm="12" vs-type="flex">
                          <!-- Add To Cart Button -->
                          <vs-button
                            @click="toggleItemInCart(item_data)"
                            class="w-full mb-4 mt-5"
                            icon="icon-shopping-cart"
                            icon-pack="feather"
                            v-if="!isInCart(item_data.id)">
                            ADD TO CART
                          </vs-button>
                          <vs-button
                            @click="$router.push('/checkout').catch(err => {})"
                            class="w-full mb-4 mt-5"
                            icon="icon-shopping-cart"
                            icon-pack="feather"
                            v-else>
                            VIEW IN CART
                          </vs-button>
                          <!-- /Add To Cart Button -->
                        </vs-col>
                      </vs-row>


                    </div>
                  </div>
                </div>
                <!-- /Quantity -->

              </div>

            </div>
          </div>
          <!-- Product Feature/Meta Row -->
        </div>

      </template>
    </vx-card>

    <vx-card class="mt-5">
      <div class="mt-24 text-center item-features">
        <div class="vx-row">
          <div v-for="feature in (item_data.display_features || [])" class="vx-col mb-24 md:w-1/3 w-full">
            <div class="w-64 mx-auto mb-16 md:mb-0">
              <feather-icon class="block mb-4" :icon="`${feature.icon}Icon`" svgClasses="h-12 w-12 text-primary stroke-current"/>
              <span class="font-semibold text-lg">{{ feature.name }}</span>
              <p class="mt-2">
                {{ feature.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

  </div>
</template>

<script>
import 'swiper/dist/css/swiper.min.css';
import {swiper, swiperSlide} from 'vue-awesome-swiper';
import vSelect from 'vue-select';
import StarRating from 'vue-star-rating';

export default {
  components: {
    swiper,
    swiperSlide,
    vSelect,
    StarRating,
  },
  data() {
    return {

      item_data: {
        description: ''
      },
      is_bundle: false,
      service_data: {},
      prices_data: {},

      price_selected: {
        priceDisplay: 'loading...',
      },

      error_occured: false,
      error_msg: '',
      prices: [],

      // Related Products Swiper
      swiperOption: {
        slidesPerView: 5,
        spaceBetween: 55,
        breakpoints: {
          1600: {
            slidesPerView: 4,
            spaceBetween: 55,
          },
          1300: {
            slidesPerView: 3,
            spaceBetween: 55,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 55,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 55,
          },
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },

      related_items: [],
    };
  },
  computed: {
    isInCart() {
      return (itemId) => this.$store.getters['eCommerce/isInCart'](itemId);
    },
  },
  methods: {
    updateSelectedPrice (a){
      this.price_selected = a;
    },
    toggleItemInCart(item) {

      if (!this.price_selected.priceId && !this.is_bundle) {
        return this.$vs.notify({
          color: 'danger',
          title: 'Error while adding item',
          text: `Please select a pricing option to add it to the cart`,
        });
      }


      item.service_id = this.service_data.id;
      item.service = this.service_data;

      item.price_id = this.price_selected.priceId;
      item.price_display = this.price_selecPayment

      if (!this.is_bundle) {
        item.price = this.prices_data.filter(price => item.price_id === price.id)[0];
        this.$store.dispatch('eCommerce/toggleItemInCart', item);
      } else {
        this.$store.dispatch('eCommerce/toggleItemInCart', item);
      }

    },
    fetch_item_details(id) {

      this.$http.get(`billing/products/${id}`)
        .then(response => {

          if (response.status === 200) {
            this.item_data = response.data.data;

            if(response.data.data.type === 'bundle') {
              this.is_bundle = true
            }

            this.$http.get(`services/${this.item_data.service_id}`)
              .then(response => {

                if (response.status === 200) {
                  this.service_data = response.data.data;

                }

              });

            this.$http.get(`billing/products/${id}/prices`)
              .then(response => {

                if (response.status === 200) {
                  this.prices_data = response.data.data;
                  this.prices_data = this.prices_data
                    .filter(price => !price.hidden && !price.archived);

                  this.prices = this.prices_data.map(price => {

                    let plural = price.interval_count === 1 ? '' : 's';

                    let interval = !price.recurring ? 'one-time' : `w/ access for ${price.interval_count} ${price.interval}${plural}`;

                    let currency = price.amount
                      .toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      });

                    let payload = {
                      priceId: price.id,
                      priceDisplay: `${currency} ${interval}`,
                    };

                    if (!this.price_selected.priceId) {
                      this.price_selected = payload;
                    }

                    return payload;
                  });

                }

              });

          }

        })
        .catch(exception => {

        });

    },
  },
  created() {
    this.fetch_item_details(this.$route.params.productId);
  },
};
</script>

<style lang="scss">

@import "@/assets/scss/vuexy/_variables.scss";

#item-detail-page {
  .color-radio {
    height: 2.28rem;
    width: 2.28rem;

    > div {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .item-features {
    //background-color: #f7f7f7;

    .theme-dark & {
      background-color: #1B1E22;
      border-top: #161719;
    }
  }

  .product-sales-meta-list {
    .vs-list--icon {
      padding-left: 0;
    }
  }

  .related-product-swiper {
    // padding-right: 2rem;
    // padding-left: 2rem;

    .swiper-wrapper {
      padding-bottom: 2rem;

      > .swiper-slide {
        background-color: #f7f7f7;
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.1), 0 5px 12px 0 rgba(0, 0, 0, 0.08) !important;

        .theme-dark & {
          background-color: $theme-light-dark-bg;
        }
      }
    }

    .swiper-button-next,
    .swiper-button-prev {
      transform: scale(.5);
      filter: hue-rotate(40deg);
    }

    .swiper-button-next {
      right: 0
    }

    .swiper-button-prev {
      left: 0;
    }
  }
}
</style>
